@font-face {
    font-family: 'Inter-Regular';
    src: url(fonts/Inter-Regular.woff2);
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
}
body{
    margin: 0;
    font-family: 'Inter-Regular';
    color: #070725;
    font-size: 14px;
}
.wrapper {
    width: 100%;
    display: flex;
}
.progress-blocks {
    background-color: #F5F5F5;
    width: 100%;
}
.progress-block {
    background-color: #fff;
    padding: 20px;
    font-size: 16px;
}
.info-map-switcher {
    display: flex;
    border-radius: 10px;
    border: 1px solid #E2E2E2;
    width: 100%;
    margin: 10px 0 20px 0;
}
.info-map-switcher-var {
    width: 50%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #EBEBEB;
}
.info-map-switcher-var:first-child {
    border-radius: 10px 0 0 10px;
}
.info-map-switcher-var:last-child {
    border-radius: 0 10px 10px 0;
}
.info-map-switcher-var img {
    margin-right: 5px;
}
.info-map-switcher-var.active {
    background-color: #fff;
}
.progress-block-top {
    display: block;
    padding-bottom: 20px;
    height: calc(var(--tg-viewport-stable-height) - 210px);
}
.progress-block-info {
    font-size: 13px;
    padding: 0 2px;
    height: calc(var(--tg-viewport-stable-height) - 210px);
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.progress-block-info-lineblock {
    padding: 5px 0;
}
.progress-block-info-line {
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.pb-notes {
    width: 100%;
    padding: 20px;
    background-color: #0101010A;
    border-radius: 8px;
}
.pb-notes-title {
    font-size: 14px;
}
.pb-info-line-value {
    font-weight: bold;
    margin-left: 10px;
}
.pb-item {
    width: 46%;
}
.pb-item:first-child {
    margin-right: 20px;
}
.pb-item-title, .pb-item-info {
    font-size: 12px;
}
.pb-item-title{
    margin-bottom: 10px;
    color: #0707257A;
}
.pb-item-info {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pb-item-img {
    margin-right: 10px;
}
.pb-item-img img {
    width: 32px;
}
.progress-block-map {
    min-height: 200px;
    width: 100%;
    height: calc(var(--tg-viewport-stable-height) - 300px);
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    display: none;
}
.color-status {
    border-radius: 10px;
    margin-left: 5px;
    padding: 5px 9px;
    font-size: 14px;
    font-weight: bold;
}
.color-status.busy {
    background-color: #D9B7A24D;
    color: #A67151;
}
.color-status.superbusy {
    background-color: #A02C3933;
    color: #A02C39;
}
.submit-price-block-inputs {
    display: flex;
    flex-direction: column;
   /* justify-content: space-between;*/
    align-items: center;
    margin: 10px 0;
}
.submit-price-block input {
    width: 80%;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #01010114;
    padding-left: 10px;
}
.progress-block-map .infobox {
    position: absolute;
    top: 34px;
    left: 45px;
    width: 166px;
    height: 144px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    padding: 14px;
    font-size: 14px;
}
.progress-block-map .infobox .info-line {
    display: flex;
    margin-top: 9px;
}
.progress-block-map .infobox .info-line .info-label{
    color: #8E8E8E;
    margin-right: 5px;
}
.progress-block-map .infobox .info-line .info-value {
    font-weight: bold;
}
.pb-item-info {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 14px;
}
.pb-item-title {
    margin-bottom: 10px;
    color: #0707257A;
    font-size: 14px;
}
.pb-item-img {
    margin-right: 10px;
}
.pb-item-img img {
    width: 32px;
}
/* REMOVE */
.progress-block-map img {
    width: 100%;
    height: calc(var(--tg-viewport-stable-height) - 200px);
}

input[type="text"]
{
    font-size:18px;
}

.view-map {
    padding: 0;
    margin: 0;
    width: 100%;
    height: calc(var(--tg-viewport-stable-height) - 250px);
    /*min-height: 300px;*/
    /*height: 300px;*/
}

#bid-button {
    background-color: rgb(51,144,236); /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 20px;
    width: 100%;
}

#return-button {
    background-color: rgb(156, 39, 176);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 20px;
    width: 100%;
}

.create-bid {
    width: 80%!important;
}

.submit-price-block {
    width: 100%;
    margin-top: 5%;
}

.progress-block-bottom {
    width: 95%;
    margin: auto;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
}

input[name=price] {
    margin-bottom: 20px;
}

input[name=rpm] {
    margin-top: 20px;
}
